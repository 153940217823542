import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  ContactsOutlined,
  ContainerOutlined,
  EnvironmentOutlined,
  ExperimentOutlined,
  FileOutlined,
  FormOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons/lib';

import { FileDoneOutlined } from '@ant-design/icons';
import Users from '../../../../../pages/users';
import Records from '../../../../../pages/records';
import Settings from '../../../../../pages/settings';
import { Route } from '../../../../../routes';
import QcReports from '../../../../../pages/qcReports';
import LpReports from '../../../../../pages/lpReports';
import Templates from '../../../../../pages/templates';
import Customers from '../../../../../pages/customers';
import Warehouses from '../../../../../pages/warehouses';
import { useAuth } from '../../../../../context/auth';
import Commodities from '../../../../../pages/commodities';
import RecordsUpdate from '../../../../../pages/records/update';
import LpReportTypes from '../../../../../pages/lpReportTypes';
import LpReportCreate from '../../../../../pages/lpReports/create';
import TemplateCreate from '../../../../../pages/templates/create';
import TemplateUpdate from '../../../../../pages/templates/update';
import QcReportCreate from '../../../../../pages/qcReports/create';
import LpReportProfile from '../../../../../pages/lpReports/profile';
import LpReportGenerate from '../../../../../pages/lpReports/generate';
import GenericTemplate from '../../../../../pages/templates/genericTemplate';
import WarehouseCreate from '../../../../../pages/warehouses/create';
import WarehouseUpdate from '../../../../../pages/warehouses/update';
import { SpaOutlined } from '../../../../Common/Icon';
import QcReportProfile from '../../../../../pages/qcReports/profile';
import CreateTemplate from '../../../../../pages/qcReports/createTemplate';
import UpdateTemplate from '../../../../../pages/qcReports/updateTemplate';
import QcReportGenerate from '../../../../../pages/qcReports/generate';
import UpdateLpTemplate from '../../../../../pages/lpReports/updateTemplate';
import QcReportTemplateProfile from '../../../../../pages/qcReports/profileTemplate';
import LpReportTemplateProfile from '../../../../../pages/lpReports/profileTemplate';
import CompletedReports from '../../../../../pages/completedReports';
import QcReportRegenerate from '../../../../../pages/qcReports/regenerate';
import { useUnsavedRegenerate } from '../../../../../context/unsaveRegenerate';
import LpReportRegenerate from '../../../../../pages/lpReports/regenerate';
import ReassignReport from '../../../../Pages/QcReports/ReassignReport';

const { SubMenu } = Menu;

export const routes: Route[] = [
  {
    bind: {
      element: <Records />,
      path: '/records',
    },
    name: 'Records',
    icon: <ContainerOutlined />,
    children: [
      {
        bind: {
          element: <RecordsUpdate />,
          path: '/update/:id',
        },
        name: 'Records Edit',
        hidden: true,
      },
    ],
  },
  {
    bind: {
      path: '/reports',
    },
    name: 'Reports',
    icon: <ExperimentOutlined />,
    children: [
      {
        bind: {
          element: <QcReports />,
          path: '/qc',
        },
        name: 'QC Reports',
        children: [
          {
            bind: {
              element: <QcReportCreate />,
              path: '/create',
            },
            name: 'QC Report Create',
            hidden: true,
            children: [
              {
                bind: {
                  element: <CreateTemplate />,
                  path: '/:id',
                },
                name: 'QC Report Create Template',
                hidden: true,
              },
            ],
          },
          {
            bind: {
              element: <QcReportCreate />,
              path: '/:id/create',
            },
            name: 'QC Report Create',
            hidden: true,
          },
          {
            bind: {
              element: <QcReportProfile />,
              path: '/:id',
            },
            name: 'QC Report Details',
            hidden: true,
          },
          {
            bind: {
              element: <QcReportTemplateProfile />,
              path: '/:id/template/:templateId',
            },
            name: 'QC Report Template Details',
            hidden: true,
          },
          {
            bind: {
              element: <UpdateTemplate />,
              path: '/update/:id/template/:templateId',
            },
            name: 'QC Report Edit Template',
            hidden: true,
          },
          {
            bind: {
              element: <QcReportGenerate />,
              path: '/generate/:id',
            },
            name: 'QC Report Generate',
            hidden: true,
          },
          {
            bind: {
              element: <QcReportRegenerate />,
              path: '/regenerate/:id',
            },
            name: 'QC Report Regenerate',
            hidden: true,
          },
          {
            bind: {
              element: <ReassignReport type="qc" />,
              path: '/reassign/:id',
            },
            name: 'Reassign the Report',
            hidden: true,
          },
        ],
      },
      {
        bind: {
          element: <LpReports />,
          path: '/lp',
        },
        name: 'LP Reports',
        children: [
          {
            bind: {
              element: <LpReportCreate />,
              path: '/create',
            },
            name: 'LP Report Create',
            hidden: true,
          },
          {
            bind: {
              element: <LpReportCreate />,
              path: '/:id/create',
            },
            name: 'LP Report Create',
            hidden: true,
          },
          {
            bind: {
              element: <UpdateLpTemplate />,
              path: '/update/:id/template/:templateId',
            },
            name: 'LP Report Edit Template',
            hidden: true,
          },
          {
            bind: {
              element: <LpReportProfile />,
              path: '/:id',
            },
            name: 'LP Report Details',
            hidden: true,
          },
          {
            bind: {
              element: <LpReportGenerate />,
              path: '/generate/:id',
            },
            name: 'LP Report Generate',
            hidden: true,
          },
          {
            bind: {
              element: <LpReportRegenerate />,
              path: '/regenerate/:id',
            },
            name: 'LP Report Regenerate',
            hidden: true,
          },
          {
            bind: {
              element: <LpReportTemplateProfile />,
              path: '/:id/template/:templateId',
            },
            name: 'QC Report Template Details',
            hidden: true,
          },
          {
            bind: {
              element: <ReassignReport type="lp" />,
              path: '/reassign/:id',
            },
            name: 'Reassign the Report',
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    bind: {
      element: <Templates />,
      path: '/templates',
    },
    name: 'Templates',
    icon: <FormOutlined />,
    children: [
      {
        bind: {
          element: <TemplateCreate />,
          path: '/create',
        },
        name: 'Templates Create',
        hidden: true,
        roleNeeded: 'manager',
      },
      {
        bind: {
          element: <TemplateUpdate />,
          path: '/update/:id',
        },
        name: 'Templates Edit',
        hidden: true,
        roleNeeded: 'manager',
      },
      {
        bind: {
          element: <GenericTemplate />,
          path: '/generic-template/:id',
        },
        name: 'Generic Template',
        hidden: true,
      },
    ],
  },
  {
    bind: {
      element: <Commodities />,
      path: '/commodities',
    },
    name: 'Commodities',
    icon: <SpaOutlined width={14} height={14} />,
  },
  {
    bind: {
      element: <Customers />,
      path: '/customers',
    },
    name: 'Customers',
    icon: <ContactsOutlined />,
  },
  {
    bind: {
      element: <LpReportTypes />,
      path: '/lp-report-types',
    },
    name: 'LP report types',
    icon: <FileOutlined />,
    roleNeeded: 'admin',
  },
  {
    bind: {
      element: <Warehouses />,
      path: '/warehouses',
    },
    name: 'Warehouses',
    icon: <EnvironmentOutlined />,
    children: [
      {
        bind: {
          element: <WarehouseCreate />,
          path: '/create',
        },
        name: 'Warehouses Create',
        hidden: true,
        roleNeeded: 'admin',
      },
      {
        bind: {
          element: <WarehouseUpdate />,
          path: '/update/:id',
        },
        name: 'Warehouses Edit',
        hidden: true,
        roleNeeded: 'manager',
      },
    ],
    roleNeeded: 'manager',
  },
  {
    bind: {
      element: <Users />,
      path: '/users',
    },
    name: 'Users',
    icon: <TeamOutlined />,
    roleNeeded: 'manager',
  },
  {
    bind: {
      element: <Settings />,
      path: '/settings',
    },
    name: 'Settings',
    icon: <SettingOutlined />,
    roleNeeded: 'admin',
  },
  {
    bind: {
      element: <CompletedReports />,
      path: '/completed-reports',
    },
    name: 'Completed reports',
    icon: <FileDoneOutlined />,
    roleNeeded: 'manager',
  },
];

const MenuCommon: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const { isRoleEnough } = useAuth();
  const { condition, regenerateUnsavedModal } = useUnsavedRegenerate();

  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  const shownRoutes = routes.filter((item) => isRoleEnough(item.roleNeeded || 'user'));

  return (
    <>
      {key && (
        <Menu
          mode="inline"
          style={{ overflow: 'hidden auto' }}
          selectedKeys={[pathname, `/${key}`]}
          defaultOpenKeys={[`/${key}`]}
          defaultSelectedKeys={[pathname, `/${key}`]}
          onClickCapture={(e) => {
            if (!condition) {
              e.preventDefault();
              const path = new URL((e.target as HTMLAnchorElement).href).pathname;

              regenerateUnsavedModal(path);
            }
          }}
        >
          {shownRoutes.map((route) => {
            const children = route.children?.filter(({ hidden }) => !hidden);

            return children?.length ? (
              <SubMenu icon={route.icon} title={route.name} key={route.bind.path}>
                {children.map((subRoute) => (
                  <Menu.Item key={`${route.bind.path}${subRoute.bind.path}`} icon={subRoute.icon}>
                    <Link to={`${route.bind.path}${subRoute.bind.path}`}>
                      {subRoute.name}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item icon={route.icon} key={route.bind.path}>
                <Link to={route.bind.path || '/'}>
                  {route.name}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default MenuCommon;
