import React, { useEffect } from 'react';
import { Card, Col, Form, Input, Row } from 'antd';

import { useAuth } from '../../../../../context/auth';
import { required } from '../../../../../utils/inputRules';
import SelectStatus from '../../../../Common/SelectStatus';
import { useContextWarehouseUpdate } from '../../../../../context/warehouseUpdate';

const GeneralInfo: React.FC = (): JSX.Element => {
  const { isRoleEnough } = useAuth();
  const { onSave, warehouseId, values, setValue } = useContextWarehouseUpdate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (form) {
      form.setFieldsValue(values);
    }
  }, [form, values]);

  return (
    <Card title="General info" style={{ marginBottom: '24px' }} loading={warehouseId?.loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        className="warehouse_update"
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={required}>
              <Input
                type="text"
                onChange={({ target: { value } }) => setValue({ name: value })}
                disabled={!isRoleEnough('admin')}
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="location" label="Location">
              <Input
                type="text"
                onChange={({ target: { value } }) => setValue({ location: value })}
                disabled={!isRoleEnough('admin')}
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <Form.Item name="status" label="Status" rules={required}>
              <SelectStatus
                onChange={(status) => setValue({ status })}
                disabled={!isRoleEnough('admin')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GeneralInfo;
