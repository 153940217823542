import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Templates/GenericTemplate';
import { displayName } from '../../../config';
import GenericTemplateProvider from '../../../context/genericTemplate';

const TemplateCreate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Generic Template`;

  return (
    <Layout>
      <GenericTemplateProvider>
        <Content />
      </GenericTemplateProvider>
    </Layout>
  );
};

export default TemplateCreate;
