import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Warehouses/Update';
import { displayName } from '../../../config';
import WarehousesUpdateProvider from '../../../context/warehouseUpdate';

const WarehouseUpdate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Warehouse Edit`;

  return (
    <Layout>
      <WarehousesUpdateProvider>
        <Content />
      </WarehousesUpdateProvider>
    </Layout>
  );
};

export default WarehouseUpdate;
