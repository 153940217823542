import React, { FC, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, PageHeader } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons/lib/icons';
import axios from 'axios';
import confirm from '../../../../Common/ModalConfirm';
import { QcReportsId } from '../../../../../hooks/qcReports';
import { LpReportsId } from '../../../../../hooks/lpReports';
import { useRecordId } from '../../../../../hooks/records';
import { useAuth } from '../../../../../context/auth';
import error from '../../../../Common/ModalError';

interface IReassignPageHeader {
  currentReport: QcReportsId | LpReportsId;
  selectedRecordId: number | undefined;
}

const ReassignPageHeader: FC<IReassignPageHeader> = ({
  currentReport,
  selectedRecordId,
}) => {
  const navigate = useNavigate();
  const getRecord = useRecordId();
  const { token } = useAuth();

  const [selectedReportName, setSelectedReportName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const routes = useMemo(() => [
    {
      path: `/reports/${currentReport.category === 'qc' ? 'qc' : 'lp'}`,
      breadcrumbName: `${currentReport.category === 'qc' ? 'QC' : 'LP'} Reports`,
    },
    { breadcrumbName: 'Reassign the Report' },
  ], [currentReport]);

  useEffect(() => {
    if (selectedRecordId) getRecordById();
  }, [selectedRecordId]);

  const getRecordById = async () => {
    const selectedReport = await getRecord.fetch(undefined, selectedRecordId);

    setSelectedReportName(selectedReport?.bolAwb || '');
  };

  const handleMove = () => {
    confirm({
      icon: <WarningOutlined />,
      title: 'Move Report',
      content: `Are you sure you want to move this report from
      ${currentReport.record.type.toUpperCase()} ${currentReport.record.bolAwb}
      to ${currentReport.record.type.toUpperCase()} ${selectedReportName}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        setIsLoading(true);

        await axios.post(
          `${process.env.REACT_APP_API_URL}/reports/${currentReport.id}/change-record`,
          { record: selectedRecordId },
          {
            headers: {
              Authorization: `Bearer ${token || ''}`,
            },
          },
        ).then(() => {
          navigate(`/reports/${currentReport.category}/${currentReport.id}`, { replace: true });
        }).catch(() => {
          error({
            title: 'Error',
            content: 'Something went wrong',
            okText: 'Ok',
          });
        });

        setIsLoading(false);
      },
    });
  };

  return (
    <div>
      <PageHeader
        title="Reassign the Report"
        extra={(
          <Button
            type="primary"
            onClick={handleMove}
            disabled={selectedRecordId === undefined}
            loading={isLoading}
          >
            Move
          </Button>
        )}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
    </div>
  );
};

export default ReassignPageHeader;
