import React, { useState } from 'react';
import { Button, Card, Checkbox, Space, Typography } from 'antd';

import { QcReportTemplate } from '../../../../../hooks/qcReports';

import styles from './index.module.less';

interface LpReportGenerateStep {
  values: QcReportTemplate;
  template: QcReportTemplate;
  setValues: (value: QcReportTemplate) => void;
  timestamp: boolean;
  handleTimestamp: (checked: boolean) => void;
}

const LpReportGenerateStep: React.FC<LpReportGenerateStep> = ({
  values,
  template,
  setValues,
  timestamp,
  handleTimestamp,
}) => {
  const [isAllSelect, setIsAllSelect] = useState<boolean>(true);

  return (
    <Card
      title="Media"
      style={{ marginBottom: 24 }}
      extra={(
        <Space>
          <Checkbox checked={timestamp} onChange={({ target: { checked } }) => handleTimestamp(checked)}>
            Add timestamp to photos
          </Checkbox>
          <Button
            type="ghost"
            onClick={() => {
              setValues({ ...values, images: isAllSelect ? [] : template.images });
              setIsAllSelect(!isAllSelect);
            }}
          >
            {isAllSelect ? 'Deselect all' : 'Select All'}
          </Button>
        </Space>
      )}
    >
      <Typography>Please deselect photos that should not be included in the report.</Typography>
      <div className={styles.wrapper}>
        {template.images.map((image) => (
          <div key={image.id} className={styles.imageWrapper}>
            <div
              style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}/images/${image.id})` }}
              className={styles.img}
            />
            <div className={styles.date} style={{ display: timestamp ? 'block' : 'none' }}>
              {image.timeStampString}
            </div>
            <Checkbox
              checked={values.images.map(({ id }) => id).includes(image.id)}
              onChange={({ target: { checked } }) => setValues({
                ...values,
                images: checked ? [...values.images, image] : values.images.filter(({ id }) => id !== image.id),
              })}
              className={styles.checkbox}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default LpReportGenerateStep;
