import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, PageHeader } from 'antd';

import TableUsers from '../../Users/Table';
import GeneralInfo from './GeneralInfo';
import { useContextWarehouseCreate } from '../../../../context/warehouseCreate';

const routes = [
  {
    path: '/warehouses',
    breadcrumbName: 'Warehouses',
  },
  {
    breadcrumbName: 'Add New Warehouse',
  },
];

const WarehouseCreate: React.FC = () => {
  const navigate = useNavigate();
  const { isDisabled, onSave, values, setValue, warehouseCreate } = useContextWarehouseCreate();

  return (
    <>
      <PageHeader
        title="Add New Warehouse"
        extra={(
          <Button type="primary" disabled={isDisabled} onClick={onSave} loading={warehouseCreate?.loading}>
            Save
          </Button>
        )}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <GeneralInfo />
        <TableUsers
          params={{ status: 'true' }}
          selectedRows={values.users}
          onRowSelection={(users) => setValue({ users })}
        />
      </Content>
    </>
  );
};

export default WarehouseCreate;
