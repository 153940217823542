import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import TableRecords from '../../Records/Table';
import ReassignPageHeader from './Header';
import { IRecordTypes, IReportTypes } from '../../../../types';
import { QcReportsId, useQcReportsId } from '../../../../hooks/qcReports';
import { LpReportsId, useLpReportsId } from '../../../../hooks/lpReports';
import Loading from '../../../Common/Loading';

interface IReassignReport {
  type: IReportTypes;
}

const ReassignReport: FC<IReassignReport> = ({ type }) => {
  const { id: reportId = '' } = useParams<{ id: string; }>();
  const qcReportsId = useQcReportsId();
  const lpReportsId = useLpReportsId();

  const [selectedRecordId, setSelectedRecordId] = useState<number | undefined>(undefined);
  const [currentReport, setCurrentReport] = useState<QcReportsId | LpReportsId | null>(null);

  useEffect(() => {
    getReport();
  }, [reportId]);

  const getReport = async () => {
    let res;

    if (type === 'qc') {
      res = await qcReportsId.fetch(undefined, reportId);
    } else {
      res = await lpReportsId.fetch(undefined, reportId);
    }

    setCurrentReport(res || null);
  };

  return (
    <div style={{ width: '100%' }}>
      {currentReport ? (
        <>
          <ReassignPageHeader currentReport={currentReport} selectedRecordId={selectedRecordId} />
          <Content>
            <TableRecords
              params={{
                type: currentReport?.record.type as IRecordTypes || 'bol',
                warehouse: currentReport.record?.warehouse?.id?.toString() || undefined,
                cutStartDate: '',
                cutEndDate: '',
              }}
              isReassign
              exceptRecordId={currentReport.record.id}
              selectedRows={selectedRecordId ? [selectedRecordId] : undefined}
              onRowSelection={(records) => setSelectedRecordId(records.length && records[0])}
              hideInSearch={['type', 'warehouse']}
              removeLink
            />
          </Content>
        </>
      ) : <Loading visible absolute size="default" />}
    </div>
  );
};

export default ReassignReport;
