import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { LpReportsId, useLpReportsId } from '../hooks/lpReports';
import { FetchCreate, FetchGetId, getMessageInError } from '../hooks/fetch';
import { QcReportTemplate, ReportGenerateParama, useReportGenerate } from '../hooks/qcReports';
import { ISelectedImagesIdsOnEachStep } from './qcReportGenerate';

interface LpReportsGenerateContext {
  onSave: () => void;
  values: QcReportTemplate[];
  setValue: (values: QcReportTemplate[]) => void;
  isDisabled: boolean;
  lpReportsId: FetchGetId<LpReportsId> | null;
  reportGenerate: FetchCreate<FetchSuccess, FetchError, ReportGenerateParama> | null;
  timestamp: boolean;
  handleTimestamp: (checked: boolean) => void;
  regenerate: boolean;
  initialSelectedIds: ISelectedImagesIdsOnEachStep;
}

const defaultValue: LpReportsGenerateContext = {
  onSave: () => undefined,
  values: [],
  setValue: () => undefined,
  isDisabled: false,
  lpReportsId: null,
  reportGenerate: null,
  timestamp: true,
  handleTimestamp: () => undefined,
  regenerate: false,
  initialSelectedIds: {},
};

export const LpReportsGenerateContext = createContext<LpReportsGenerateContext>(defaultValue);

interface ILpReportsGenerateProvider {
  children: React.ReactNode;
  regenerate?: boolean;
}

const LpReportsGenerateProvider: React.FC<ILpReportsGenerateProvider> = ({ children, regenerate }) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string; }>();
  const lpReportsId = useLpReportsId();
  const reportGenerate = useReportGenerate();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<QcReportTemplate[]>([]);
  const [timestamp, setTimestamp] = useState<boolean>(true);

  const [
    initialSelectedIds,
    setInitialSelectedIds,
  ] = useState<ISelectedImagesIdsOnEachStep>({});

  useEffect(() => {
    if (values.length && !Object.keys(initialSelectedIds).length) {
      const initialIds = values.reduce((acc, obj, currentIndex) => {
        acc[currentIndex] = obj.prevImages;

        return acc;
      }, {} as ISelectedImagesIdsOnEachStep);

      setInitialSelectedIds(initialIds);
    }
  }, [values]);

  const handleTimestamp = (checked: boolean) => {
    setTimestamp(checked);
  };
  const handleValuesChange = (value: QcReportTemplate[]) => setValues(value);

  useEffect(() => {
    const isValid = values.every((template) => (
      template?.reportTemplateFields
        .filter((field) => field.templateField.required)
        .every((field) => !!field.value)
      && template.images.length
    ));

    setIsDisabled(regenerate ? values?.some((el) => !el.prevImages.length) : !isValid);
  }, [values]);

  const onSave = async () => {
    const uniqueImagesIds = Array.from(
      new Set(values.map((el) => el.prevImages).flat()),
    );

    await reportGenerate.fetch({
      report: +id,
      images: regenerate ? uniqueImagesIds : (
        values.reduce((acc: number[], template) => (
          [...acc, ...template.images.map((image) => image.id)]
        ), [])
      ),
      addDataStump: timestamp,
    });
  };

  useEffect(() => {
    if (reportGenerate.data && !reportGenerate.error) {
      message.success('PDF successfully generated.');
      navigate(`/reports/lp/${id}`, { replace: true });
    }
  }, [reportGenerate.data]);

  useEffect(() => {
    if (reportGenerate.error) {
      message.error(getMessageInError(reportGenerate.error));
      reportGenerate.clearError();
    }
  }, [reportGenerate.error]);

  useEffect(() => {
    if (id) {
      lpReportsId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (lpReportsId.data && !lpReportsId.error) {
      setTimestamp(lpReportsId.data.addDataStump || lpReportsId.data.status === 'in progress');
      setValues(lpReportsId.data.reportTemplates);
    }
  }, [lpReportsId.data]);

  useEffect(() => {
    if (lpReportsId.error) {
      message.error(getMessageInError(lpReportsId.error));
      lpReportsId.clearError();
    }
  }, [lpReportsId.error]);

  return (
    <LpReportsGenerateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        lpReportsId,
        reportGenerate,
        timestamp,
        handleTimestamp,
        regenerate: regenerate || false,
        initialSelectedIds,
      }}
    >
      {children}
    </LpReportsGenerateContext.Provider>
  );
};

LpReportsGenerateProvider.defaultProps = {
  regenerate: false,
};

export default LpReportsGenerateProvider;

export const useContextLpReportGenerate = (): LpReportsGenerateContext => useContext(LpReportsGenerateContext);
