import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';
import { TemplateField, TemplateId, useTemplateGeneric, useTemplatesUpdate } from '../hooks/templates';

type ValuesChangeType = { templateFields: TemplateField[]; }

interface GenericTemplateContext {
  onSave: () => void;
  values: GenericTemplateUpdateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  templateGeneric: FetchGetId<TemplateId> | null;
  templatesUpdate: FetchUpdate<FetchSuccess, FetchError, FormData> | null;
}

export const initialValues: GenericTemplateUpdateParams = {
  templateFields: [
    {
      id: 0,
      name: '',
      type: 'text',
      value: [],
      required: true,
      position: 0,
    },
  ],
};

const defaultValue: GenericTemplateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  templateGeneric: null,
  templatesUpdate: null,
};

interface GenericTemplateUpdateParams {
  templateFields: TemplateField[];
}

export const GenericTemplateContext = createContext<GenericTemplateContext>(defaultValue);

const GenericTemplateProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const templateGeneric = useTemplateGeneric();
  const templatesUpdate = useTemplatesUpdate();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<GenericTemplateUpdateParams>(initialValues);
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    templateGeneric.fetch();
  }, []);

  useEffect(() => {
    if (templateGeneric.data && !templateGeneric.error) {
      setValues({
        templateFields: (
          templateGeneric.data.templateFields.length ? (
            templateGeneric.data.templateFields
              .sort((prev, next) => prev.position < next.position ? -1 : 1)
              .map((field): TemplateField => ({
                ...field, value: field.value.split(',').map((value, i) => ({ id: i, value })),
              }))) : [{ id: 0, name: '', type: 'text', value: [], required: true, position: 0 }]
        ),
      });
    }
  }, [templateGeneric.data]);

  useEffect(() => {
    if (templateGeneric.error) {
      message.error(getMessageInError(templateGeneric.error));
      templateGeneric.clearError();
    }
  }, [templateGeneric.error]);

  useEffect(() => {
    const isValid = templateGeneric.data && values.templateFields.every((field) => !!field.name)
      && values.templateFields.every((field) => (
        field.type !== 'select' || field.value.every((value) => value.value)));

    setIsDisabled(!isValid);
  }, [values.templateFields]);

  const onSave = () => {
    if (!isDisabled && templateGeneric.data) {
      const formData = new FormData();

      formData.append('name', templateGeneric.data.name);
      formData.append('status', `${templateGeneric.data.status}`);
      formData.append('commodity', templateGeneric.data.commodity?.id.toString() || '0');
      templateGeneric.data.customers.forEach((customer) => {
        formData.append('customers[]', `${customer.id}`);
      });
      values.templateFields.forEach(({ name, value, type, required }, position) => {
        formData.append('templateFields[]', JSON.stringify(
          {
            name,
            type,
            value: type === 'select' || type === 'grading'
              ? value.map((option) => option.value).join(',')
              : {},
            required,
            position,
          },
        ));
      });

      templatesUpdate.fetch(formData, templateGeneric.data.id);
    }
  };

  const clear = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    if (templatesUpdate.data && !templatesUpdate.error) {
      message.success('Updated!');
      navigate('/templates', { replace: true });
      clear();
    }
  }, [templatesUpdate.data]);

  useEffect(() => {
    if (templatesUpdate.error) {
      message.error(getMessageInError(templatesUpdate.error));
      templatesUpdate.clearError();
    }
  }, [templatesUpdate.error]);

  return (
    <GenericTemplateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        templateGeneric,
        templatesUpdate,
      }}
    >
      {children}
    </GenericTemplateContext.Provider>
  );
};

export default GenericTemplateProvider;

export const useContextGenericTemplate = (): GenericTemplateContext => useContext(GenericTemplateContext);
