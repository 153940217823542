import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, PageHeader, Steps } from 'antd';

import { UploadFile } from 'antd/es/upload/interface';
import { Step } from '../../../../types';
import TableLpReportTypes from '../../LpReportTypes/Table';
import UploadReportPictures from '../../../Common/UploadReportPictures';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateInWork } from '../../../../hooks/qcReports';
import { useContextLpReportUpdate } from '../../../../context/lpReportUpdate';
import GalleryModal from '../../QcReports/GalleryModal';

const LpReportUpdate: React.FC = () => {
  const navigate = useNavigate();
  const reportTemplateInWork = useReportTemplateInWork();
  const {
    onSave,
    values,
    setValue,
    isDisabled,
    lpReportsId,
    lpReportUpdate,
    reportTemplateUpdate,
    setIsRouteToHome,
    setIsDisabled,
  } = useContextLpReportUpdate();

  const [images, setImages] = useState<any[]>([]);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [selectToDelete, setSelectToDelete] = useState(false);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [saveDeletedImages, SetSaveDeletedImages] = useState(false);

  const handleVisibility = () => {
    setModalVisibility(!modalVisibility);
  };

  const onUploadFilesChange = (uploadFiles: UploadFile[], removeImageId?: string) => {
    if (uploadFiles?.some((el) => el.status === 'uploading') || uploadFiles?.some((el: any) => el?.entity)) {
      setImages((prevState) => ([
        ...prevState,
        ...uploadFiles
          .filter((el) => !prevState.some(({ uid }) => uid.toString() === el.uid.toString())),
      ]));
    }

    if (uploadFiles?.some((el) => el.status === 'done')) {
      if (images.length) {
        setImages((prevState) => ([
          ...prevState.map((obj) => uploadFiles.find((el) => el.uid === obj.uid) || obj),
        ]));
      } else {
        setImages(uploadFiles);
      }
    }

    if (removeImageId?.length) {
      setValue({
        galleryImages: values.galleryImages.filter(({ id }) => id !== +removeImageId),
        oldImages: values.oldImages.filter(({ id }) => id !== +removeImageId),
      });

      setImages(images.filter((el) => el.uid !== removeImageId));
    }
  };

  useEffect(() => {
    setValue({ images });
  }, [images]);

  const inWork = () => {
    reportTemplateInWork.fetch(undefined, `${lpReportsId?.data?.reportTemplates[0].id}/in-work`);
  };

  useEffect(() => {
    if (lpReportsId?.data?.reportTemplates[0].id) {
      inWork();
      const id = setInterval(inWork, 50000);

      return () => clearInterval(id);
    }

    return undefined;
  }, [lpReportsId?.data?.reportTemplates[0].id]);

  const [currentStep, setCurrentStep] = useState(1);

  const routes = [
    {
      path: '/reports/lp',
      breadcrumbName: 'LP Reports',
    },
    {
      path: `/reports/lp/${values.report}`,
      breadcrumbName: lpReportsId?.data?.record.bolAwb ? `AWB ${lpReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: 'Edit LP Report',
    },
  ];

  const next = () => {
    if (currentStep === 0 && lpReportsId?.data?.type?.id && +lpReportsId.data.type.id !== values.type) {
      const formData = new FormData();

      formData.append('type', values.type.toString());
      formData.append('record', lpReportsId.data.record.id.toString());
      formData.append('status', lpReportsId.data.status);

      lpReportsId.data.reportTemplates[0].images.forEach((image) => {
        formData.append('oldImages[]', image.id.toString());
      });

      lpReportUpdate?.fetch(formData, lpReportsId.data.id);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (lpReportUpdate?.data && !lpReportUpdate.error) {
      setCurrentStep(currentStep + 1);
    }
  }, [lpReportUpdate?.data]);

  useEffect(() => {
    if (lpReportUpdate?.error) {
      getMessageInError(lpReportUpdate.error);
      lpReportUpdate.clearError();
    }
  }, [lpReportUpdate?.error]);

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const SelectToDelete = () => {
    setIsDisabled(false);
    setSelectToDelete(true);
  };

  const CancelDelete = () => {
    setIsDisabled(true);
    setSelectToDelete(false);
  };

  const DeleteSelectedImage = () => {
    setSelectToDelete(false);
    if (selectedImages.length > 0) {
      const newFileList = images.filter((file) => !selectedImages.includes(file));

      setImages(newFileList);
      setSelectedImages([]);
      const newOldImage = values.oldImages.filter(({ id }) => !selectedImages.some((s) => s.id === id));
      const newGalleryImages = values.galleryImages.filter(({ id }) => !selectedImages.some((s) => s.id === id));

      setValue({
        galleryImages: newGalleryImages,
        oldImages: newOldImage,
        images: newFileList,
      });
      setIsRouteToHome(false);
      SetSaveDeletedImages(true);
    }
  };

  useEffect(() => {
    if (!selectToDelete && saveDeletedImages) {
      onSave();
      SetSaveDeletedImages(false);
    }
  }, [selectToDelete, saveDeletedImages]);

  const steps: Step[] = [
    {
      title: 'Type',
      content: (
        <>
          {lpReportsId && (
            <Card
              title="Loading notes"
              style={{ marginBottom: '24px', fontWeight: 600 }}
              loading={lpReportsId.loading}
            >
              {lpReportsId.data?.record.loading_notes || 'There are no loading notes'}
            </Card>
          )}
          <TableLpReportTypes
            params={{ status: 'active' }}
            selectedRows={[values.type]}
            onRowSelection={(type) => setValue({ type: type[0] })}
          />
        </>
      ),
      isValid: !!values.type,
    },
    {
      title: 'Photos',
      content: (
        <Card
          title="Add Photos"
          extra={(
            <div>
              {selectToDelete === false && (
                <Button type="primary" onClick={SelectToDelete}>
                  Select to Delete
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={CancelDelete}>
                  Cancel
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={DeleteSelectedImage} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              )}
              <Button type="primary" onClick={handleVisibility} style={{ marginLeft: '10px' }}>
                Upload from Gallery
              </Button>
            </div>
          )}
        >
          <UploadReportPictures
            galleryImages={values.galleryImages}
            images={images}
            onUploadFilesChange={onUploadFilesChange}
            oldImages={values.oldImages}
            selectedImage={selectedImages}
            setSelectedImages={setSelectedImages}
            selectToDelete={selectToDelete}
          />
        </Card>
      ),
      isValid: !!(values.images.length || !!values.oldImages.length || !!values.galleryImages.length),
    },
  ];

  return (
    <>
      <PageHeader
        title="Edit LP Report"
        extra={(
          <>
            {currentStep > 0 && (
              <Button type="default" onClick={prev}>
                Back
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                onClick={next}
                loading={lpReportUpdate?.loading || lpReportsId?.loading}
                disabled={!steps[currentStep].isValid}
              >
                Next Step
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" disabled={isDisabled} onClick={onSave} loading={reportTemplateUpdate?.loading}>
                Save
              </Button>
            )}
          </>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        footer={(
          <Steps current={currentStep} style={{ maxWidth: '880px' }}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        )}
      />
      <Content>
        <GalleryModal
          visible={modalVisibility}
          title="QC gallery"
          handleVisibility={handleVisibility}
          setValue={setValue}
          checkedImages={values.galleryImages}
        />
        {steps[currentStep].content}
      </Content>
    </>
  );
};

export default LpReportUpdate;
