import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/QcReports/Generate';
import { displayName } from '../../../config';
import QcReportsGenerateProvider from '../../../context/qcReportGenerate';

const QcReportGenerate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: QC Report Generate`;

  return (
    <Layout>
      <QcReportsGenerateProvider>
        <Content />
      </QcReportsGenerateProvider>
    </Layout>
  );
};

export default QcReportGenerate;
