import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, PageHeader } from 'antd';

import { QcReportTemplate } from '../../../../hooks/qcReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateUpdate } from '../../../../hooks/reportTemplate';
import { useRecordId } from '../../../../hooks/records';

import styles from '../../LpReports/Generate/index.module.less';
import { queryFilterParams } from '../../../../utils';
import { ReportImages } from '../../../../context/qcReportUpdateTemplate';
import { useContextQcReportGenerate } from '../../../../context/qcReportGenerate';
import { useUnsavedRegenerate } from '../../../../context/unsaveRegenerate';
import QcReportRegenerateStep from './QcReportRegenerateStep';
import confirm from '../../../Common/ModalConfirm';

const QcReportRegenerate: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportTemplateUpdate = useReportTemplateUpdate();
  const recordGetId = useRecordId();
  const {
    onSave,
    values,
    setValue,
    qcReportsId,
    changeBolLogNumber,
    regenerate,
    handleTimestamp,
    timestamp,
    reportGenerate,
    isDisabled,
  } = useContextQcReportGenerate();
  const [triggerOnSave, setTriggerOnSave] = useState<boolean>(false);
  const { condition, regenerateUnsavedModal, unsavedChanges } = useUnsavedRegenerate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (triggerOnSave) {
      setTriggerOnSave(false);
      onSave();
    }
  }, [onSave, triggerOnSave]);

  const [logNumOptions, setLogNumOptions] = useState<{ id: number; bolAwb: string; log_number: string; }[]>([]);

  useEffect(() => {
    if (qcReportsId?.data?.record) {
      recordGetId.fetch(undefined, qcReportsId.data.record.id);
    }
  }, [qcReportsId?.data?.record]);

  useEffect(() => {
    if (recordGetId.data?.get_awb_records) {
      setLogNumOptions(recordGetId.data?.get_awb_records);

      if (recordGetId.data?.get_awb_records.length < 2) {
        changeBolLogNumber(recordGetId.data?.get_awb_records[0]?.log_number || '-');
      }
    }
  }, [recordGetId.data]);

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setSearchParams(queryFilterParams({
      valid: '',
      step: `${currentStep}`,
    }));
  }, [currentStep]);

  const next = () => {
    setCurrentStep(currentStep + 1);
    // checkChanges(() => setCurrentStep(currentStep + 1));
  };

  useEffect(() => {
    if (reportTemplateUpdate.error) {
      getMessageInError(reportTemplateUpdate.error);
      reportTemplateUpdate.clearError();
    }
  }, [reportTemplateUpdate.error]);

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const routes = [
    {
      path: '/reports/qc',
      breadcrumbName: 'QC Reports',
    },
    {
      path: `/reports/qc/${qcReportsId?.data?.id}`,
      breadcrumbName: qcReportsId?.data?.record.bolAwb
        ? `${qcReportsId?.data?.record.type.toUpperCase()} ${qcReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: `${regenerate ? 'Regenerate' : 'Generate'} QC Report`,
    },
  ];

  let submitBtnText = qcReportsId?.data?.record?.uid ? 'Send to A-works' : 'Generate PDF';

  if (regenerate) {
    submitBtnText = qcReportsId?.data?.record?.uid ? 'Re-send to A-Works' : 'Regenerate PDF';
  }

  let galleryImage: number[] = [];
  let checkedImages: ReportImages[] = [];

  galleryImage = values[currentStep]?.galleryImages?.length ? JSON.parse(values[currentStep].galleryImages) : [];
  checkedImages = values[currentStep]?.images.filter(({ id }) => galleryImage.includes(id));

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | undefined, path: any) => {
    if (!condition) {
      if (e) e.preventDefault();

      regenerateUnsavedModal(path);

      return;
    }

    navigate(path);
  };

  return (
    <>
      <PageHeader
        title={(
          <div className={styles.wrapBackText}>
            <span>Regenerate</span>
            <span className={styles.smallText}>
              {qcReportsId?.data?.reportTemplates[currentStep]?.template?.name || ''}
            </span>
          </div>
        )}
        extra={(
          <>
            {currentStep > 0 && <Button type="default" onClick={prev}>Back</Button>}
            {currentStep < values.length - 1 && (
              <Button
                type="primary"
                onClick={next}
                loading={reportTemplateUpdate.loading}
                disabled={isDisabled}
              >
                Next Step
                {' '}
                {`${currentStep + 1}/${values.length}`}
              </Button>
            )}
            {(currentStep === values.length - 1) && (
              <Button
                type="primary"
                disabled={isDisabled || recordGetId.loading}
                onClick={() => confirm({
                  onOk: async () => {
                    setIsLoading(true);
                    unsavedChanges(true);
                    await onSave();
                    setIsLoading(false);
                  },
                  title: qcReportsId?.data?.record.uid ? 'Re-send to A-Works?' : 'Regenerate PDF?',
                  okText: qcReportsId?.data?.record.uid ? 'Re-send to A-Works' : 'Regenerate PDF',
                  content: 'Please make sure the report is completed correctly before regenerating.',
                })}
                loading={isLoading || reportGenerate?.loading || reportTemplateUpdate.loading}
              >
                {submitBtnText}
              </Button>
            )}
          </>
        )}
        onBack={() => handleClick(undefined, -3)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path} onClick={(e) => handleClick(e, path)}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        {values.length ? (
          <QcReportRegenerateStep
            currentStep={currentStep}
            galleryImages={checkedImages}
            key={values[currentStep].id}
            timestamp={timestamp}
            handleTimestamp={handleTimestamp}
            values={values.find(({ id }) => id === values[currentStep].id) as QcReportTemplate}
            template={qcReportsId?.data?.reportTemplates.find((reportTemplate) => (
              reportTemplate.id === values[currentStep].id
            )) as QcReportTemplate}
            setValues={(value) => setValue(values.map((templateValues) => {
              if (templateValues.id === values[currentStep].id) {
                return value;
              }

              return templateValues;
            }))}
            onSave={next}
          />
        ) : <Card loading />}
      </Content>
    </>
  );
};

export default QcReportRegenerate;
