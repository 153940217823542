import React, { useEffect, useState } from 'react';

import TemplateForm from '../../../../Common/TemplateForm';
import { QcReportTemplate, TemplateFieldValue } from '../../../../../hooks/qcReports';
import { ReportImages } from '../../../../../context/qcReportUpdateTemplate';
import { useUnsavedRegenerate } from '../../../../../context/unsaveRegenerate';
import RegenerateReportMedia from '../../../../Common/RegenerateReportMedia';

interface QcReportGenerateStep {
  values: QcReportTemplate;
  onSave: () => void;
  template: QcReportTemplate;
  setValues: (value: QcReportTemplate) => void;
  timestamp: boolean;
  handleTimestamp: (checked: boolean) => void;
  galleryImages?: ReportImages[];
  currentStep: number;
}

const QcReportGenerateStep: React.FC<QcReportGenerateStep> = ({
  onSave,
  values,
  template,
  setValues,
  currentStep,
}) => {
  const [initialValues, setInitialValues] = useState<TemplateFieldValue[]>([]);
  const { unsavedChanges } = useUnsavedRegenerate();

  useEffect(() => {
    if (values.cutTemplateFields && !initialValues.length) {
      setInitialValues(values.cutTemplateFields);
    }
  }, [values.cutTemplateFields]);

  return (
    <>
      <RegenerateReportMedia
        values={values}
        setValues={setValues}
        template={template}
        isQcReport
        currentStep={currentStep}
      />
      <TemplateForm
        id={template.template.id}
        title="Quality Report"
        onSave={onSave}
        setIsDisabled={() => undefined}
        templateFieldsValues={values.cutTemplateFields || []}
        setTemplateFields={(reportTemplateFields) => {
          const isEqual = JSON.stringify(initialValues) === JSON.stringify(reportTemplateFields);

          unsavedChanges(isEqual);

          setValues({
            ...values,
            cutTemplateFields: reportTemplateFields,
          });
        }}
      />
    </>
  );
};

QcReportGenerateStep.defaultProps = {
  galleryImages: [],
};

export default QcReportGenerateStep;
