import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Warehouses/Create';
import { displayName } from '../../../config';
import WarehousesCreateProvider from '../../../context/warehouseCreate';

const WarehouseCreate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Warehouse Create`;

  return (
    <Layout>
      <WarehousesCreateProvider>
        <Content />
      </WarehousesCreateProvider>
    </Layout>
  );
};

export default WarehouseCreate;
