import moment from 'moment';
import Search from 'antd/es/input/Search';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, FormInstance, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Table from '../../../Common/Table';
import SelectTemplate from '../../../Common/SelectTemplate';
import SelectWarehouse from '../../../Common/SelectWarehouse';
import { getMessageInError } from '../../../../hooks/fetch';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import {
  CompletedTableReportRow, useCompletedReportsView,
  useTableCompetedReportRow,
} from '../../../../hooks/completedReports';
import SelectUsers from '../../../Common/SelectUsers';
import SelectLpReportType from '../../../Common/SelectLpReportType';

const TableCompletedReports: React.FC = (): JSX.Element => {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const completedReportsGet = useTableCompetedReportRow();
  const completedReportView = useCompletedReportsView();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      orderBy: searchParams.get('orderBy') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      search: value,
      bolAwb: searchParams.get('bolAwb') || '',
      id: searchParams.get('id') || '',
      key: searchParams.get('key') || '',
      warehouse: searchParams.get('warehouse') || '',
      template: searchParams.get('template') || '',
      updated: searchParams.get('updated') || '',
      created: searchParams.get('created') || '',
      generatedAt: searchParams.get('generatedAt') || '',
      viewStatus: searchParams.get('viewStatus') || 'new',
      attachments: searchParams.get('attachments') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if (completedReportsGet.error) {
      message.error(getMessageInError(completedReportsGet.error));
      completedReportsGet.clearError();
    }
  }, [completedReportsGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
  ], [searchValue, onSearch]);

  useEffect(() => {
    formRef.current?.setFieldsValue({ viewStatus: searchParams.get('viewStatus') || 'new' });
  }, []);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<CompletedTableReportRow>>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...{ ...args, viewStatus: searchParams.get('viewStatus') || 'new' },
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return completedReportsGet.fetch(params).then((data) => {
      if (data) {
        const { completedReports, total } = data;

        return ({ data: completedReports || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  }, [searchParams.get('search')]);

  const handleView = async (id: number) => {
    await completedReportView.fetch(undefined, `${id}/view`);

    actionRef.current?.reload();
  };

  const columns: ProColumns<CompletedTableReportRow>[] = [
    {
      title: () => {
        if (searchParams.get('recordType') === 'awb') return 'AWB#';
        if (searchParams.get('recordType') === 'bol') return 'BOL#';
        if (searchParams.get('recordType') === 'misc') return 'Title';

        return 'Record#';
      },
      dataIndex: 'bolAwb',
      sorter: true,
      renderText: (bolAwb, { id }) => (
        <a
          href={`${process.env.REACT_APP_BUCKET_URL}/reports/${id}/show_pdf`}
          target="_blank"
          rel="noreferrer"
          onClick={() => handleView(id)}
        >
          {bolAwb}
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'recordType',
      sorter: false,
      order: 3,
      valueEnum: {
        awb: 'AWB',
        bol: 'BOL',
        misc: 'MISC',
      },
      formItemProps: {
        label: 'Record type',
      },
      renderText: (_, { record }) => record?.type,
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      sorter: true,
      renderText: (warehouse) => warehouse?.name || '',
      renderFormItem: (_, { ...config }) => <SelectWarehouse {...config} />,
    },
    {
      title: 'Report type',
      dataIndex: 'typeId',
      sorter: true,
      hideInTable: searchParams.get('recordType') !== 'awb' && searchParams.get('recordType') !== null,
      hideInSetting: searchParams.get('recordType') !== 'awb' && searchParams.get('recordType') !== null,
      hideInSearch: searchParams.get('recordType') !== 'awb' && searchParams.get('recordType') !== null,
      hideInForm: searchParams.get('recordType') !== 'awb' && searchParams.get('recordType') !== null,
      renderText: (type) => type?.name || '',
      renderFormItem: (_, { ...config }) => <SelectLpReportType {...config} />,
    },
    {
      title: 'Template',
      dataIndex: 'templates',
      sorter: true,
      hideInTable: searchParams.get('recordType') === 'awb' && searchParams.get('recordType') !== null,
      hideInSetting: searchParams.get('recordType') === 'awb' && searchParams.get('recordType') !== null,
      hideInSearch: searchParams.get('recordType') === 'awb' && searchParams.get('recordType') !== null,
      hideInForm: searchParams.get('recordType') === 'awb' && searchParams.get('recordType') !== null,
      search: { transform: () => 'template' },
      renderText: (e, { record, templates }) => record.type === 'awb' ? '' : templates,
      renderFormItem: (_, { ...config }) => <SelectTemplate {...config} />,
    },
    {
      title: 'Updated at',
      dataIndex: 'updated',
      valueType: 'dateRange',
      sorter: true,
      render: (_, { updated }) => updated ? moment(updated).format('MM/DD/YYYY, hh:mm A') : '',
    },
    {
      title: 'Generated at',
      dataIndex: 'generatedAt',
      valueType: 'dateRange',
      sorter: true,
      render: (_, { generatedAt }) => generatedAt ? moment(generatedAt).format('MM/DD/YYYY, hh:mm A') : '',
    },
    {
      title: 'Last modified by',
      dataIndex: 'userId',
      sorter: false,
      renderText: (_, { user }) => user?.login || '',
      renderFormItem: (_, { ...config }) => <SelectUsers {...config} />,
    },
    {
      title: 'Completed by',
      dataIndex: 'completedById',
      sorter: false,
      renderText: (_, { completedBy }) => completedBy?.login || '-',
      renderFormItem: (_, { ...config }) => <SelectUsers {...config} />,
    },
    {
      title: 'Status',
      dataIndex: 'viewStatus',
      sorter: true,
      renderText: (_, { viewStatus }) => (
        <Badge
          status={viewStatus === 'new' ? 'success' : 'default'}
          style={{ textTransform: 'capitalize' }}
          text={viewStatus === 'new' ? 'Completed' : 'Viewed'}
        />
      ),
      formItemProps: {
        label: 'View Status',
      },
      valueEnum: {
        new: 'Completed',
        viewed: 'Viewed',
      },
    },
  ];

  return (
    <>
      <Table<CompletedTableReportRow>
        formRef={formRef}
        columns={columns}
        request={tableRequest}
        actionRef={actionRef}
        headerTitle="Completed reports"
        toolBarRender={toolBarRender}
        showSorterTooltip={false}
        beforeSearchSubmit={beforeSearchSubmit}
      />
    </>
  );
};

export default TableCompletedReports;
