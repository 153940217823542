import { Record, RecordUser } from './records';
import { Template } from './templates';
import { ReportCategory } from './lpReports';
import { Warehouse } from './warehouses';
import { PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchGet,
  useFetchGet, useFetchGetId,
} from './fetch';
import { LpReportType } from './lpReportTypes';

export interface CompletedReport {
  id: number;
  record: Record;
  status: string;
  created: string;
  updated: string;
  category: ReportCategory;
  generatedAt: string | null;
  attachments: string | null;
  reportTemplates: Template[];
  completedBy: RecordUser | null;
  user: RecordUser;
  viewStatus: string;
  type: LpReportType;
}

interface ReportsGetParams {
  id?: number;
  type?: string;
  page?: number;
  limit?: number;
  bolAwb?: string;
  status?: string;
  search?: string;
  typeId?: number;
  orderBy?: 'ASC' | 'DESC';
  updated?: string[];
  endDate?: string;
  template?: number;
  recordId?: number;
  warehouse?: number;
  startDate?: string;
  generatedAt?: string[];
  orderByColumn?: 'bolAwb' | 'id' | 'warehouse' | 'templates'
    | 'attachments' | 'generatedAt' | 'type' | 'updated' | 'viewStatus' | 'status';
}

export interface CompletedTableReportRow {
  id: number;
  key: number;
  record: Record;
  status: string;
  bolAwb: string;
  created: string;
  updated: string;
  templates: string;
  warehouse: Warehouse;
  generatedAt: string | null;
  attachments: string | null;
  completedBy: RecordUser | null;
  user: RecordUser;
  viewStatus: string;
  typeId: LpReportType;
}

interface ReportsTableData {
  total: number;
  completedReports: CompletedTableReportRow[];
}

export const useTableCompetedReportRow = (): FetchGet<ReportsTableData, ReportsGetParams> => (
  useCompletedReportsGet((data: PagingDataResponse<CompletedReport>): ReportsTableData => ({
    total: data.meta.totalItems,
    completedReports: data.items.map((report: CompletedReport): CompletedTableReportRow => ({
      id: report.id,
      key: report.id,
      bolAwb: report.record.bolAwb,
      record: report.record,
      status: report.status,
      updated: report.updated,
      created: report.created,
      templates: report.reportTemplates.map((template) => template.template.name).join(', '),
      warehouse: report.record.warehouse,
      generatedAt: report.generatedAt,
      attachments: report.attachments,
      completedBy: report.completedBy,
      user: report.user,
      viewStatus: report.viewStatus,
      typeId: report.type,
    })),
  }))
);

export function useCompletedReportsGet<D = PagingDataResponse<CompletedReport>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, ReportsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'reports/complited',
    { decorateData, autoStart: false },
  );
}

export const useCompletedReportsView = () => (
  useFetchGetId('reports/', '', { autoStart: false })
);
