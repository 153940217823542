import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import confirm from '../components/Common/ModalConfirm';
import { useAuth } from './auth';

interface IUnsavedRegenerate {
  unsavedChanges: (condition: boolean) => void;
  regenerateUnsavedModal: (path: string, isLogout?: boolean) => void;
  condition: boolean;
}

const defaultValue = {
  unsavedChanges: () => undefined,
  regenerateUnsavedModal: () => undefined,
  condition: false,
};

export const UnsavedRegenerateContext = createContext<IUnsavedRegenerate>(defaultValue);

const UnsavedRegenerateProvider: React.FC = ({ children }) => {
  const [condition, setCondition] = useState<boolean>(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const unsavedChanges = (payload: boolean) => {
    setCondition(payload);
  };

  const regenerateUnsavedModal = (path: string, isLogout?: boolean) => {
    confirm({
      title: 'Note',
      content: 'Your changes will not be saved once you exit this page',
      cancelText: 'Exit',
      okText: 'Stay',
      onOk: () => undefined,
      onCancel: () => {
        unsavedChanges(true);
        if (isLogout) {
          logout();
        } else {
          navigate(path);
        }
      },
    });
  };

  return (
    <UnsavedRegenerateContext.Provider
      value={{
        unsavedChanges,
        regenerateUnsavedModal,
        condition,
      }}
    >
      {children}
    </UnsavedRegenerateContext.Provider>
  );
};

export default UnsavedRegenerateProvider;

export const useUnsavedRegenerate = (): IUnsavedRegenerate => useContext(UnsavedRegenerateContext);
