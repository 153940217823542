import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/LpReports/Generate';
import { displayName } from '../../../config';
import LpReportsGenerateProvider from '../../../context/lpReportGenerate';

const LpReportGenerate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Report Generate`;

  return (
    <Layout>
      <LpReportsGenerateProvider>
        <Content />
      </LpReportsGenerateProvider>
    </Layout>
  );
};

export default LpReportGenerate;
