import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Card, PageHeader, Steps } from 'antd';

import { UploadFile } from 'antd/es/upload/interface';
import { Step } from '../../../../types';
import TemplateForm from '../../../Common/TemplateForm';
import TableTemplates from '../../Templates/Table';
import UploadReportPictures from '../../../Common/UploadReportPictures';
import { TemplateFieldValue } from '../../../../hooks/qcReports';
import { useContextQcReportCreateTemplate } from '../../../../context/qcReportCreateTemplate';
import GalleryModal from '../GalleryModal';
import { useTemplateFieldCount } from '../../../../hooks/templates';

const CreateTemplate: React.FC = () => {
  const navigate = useNavigate();

  const {
    onSave,
    values,
    setValue,
    isDisabled,
    qcReportsId,
    setIsDisabled,
    reportTemplateCreate,
  } = useContextQcReportCreateTemplate();

  const templateFieldCount = useTemplateFieldCount();
  const [countCheck, setCountCheck] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [selectToDelete, setSelectToDelete] = useState(false);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);

  const handleVisibility = () => {
    setModalVisibility(!modalVisibility);
  };

  const [images, setImages] = useState<any[]>([]);

  const onUploadFilesChange = (uploadFiles: UploadFile[], removeImageId?: string) => {
    if (uploadFiles?.some((el) => el.status === 'uploading') || uploadFiles?.some((el: any) => el?.entity)) {
      setImages((prevState) => ([
        ...prevState,
        ...uploadFiles
          .filter((el) => !prevState.some(({ uid }) => uid.toString() === el.uid.toString())),
      ]));
    }

    if (uploadFiles?.some((el) => el.status === 'done')) {
      if (images.length) {
        setImages((prevState) => ([
          ...prevState.map((obj) => uploadFiles.find((el) => el.uid === obj.uid) || obj),
        ]));
      } else {
        setImages(uploadFiles);
      }
    }

    if (removeImageId?.length) {
      setValue({
        galleryImages: values.galleryImages.filter(({ id }) => id !== +removeImageId),
      });

      setImages(images.filter((el) => el.uid !== removeImageId));
    }
  };

  useEffect(() => {
    if (values.images.length !== 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values.images]);

  useEffect(() => {
    setValue({ images });
  }, [images]);

  useEffect(() => {
    if (values.template) {
      templateFieldCount.fetch(undefined, values.template);
    }
  }, [values.template]);

  useEffect(() => {
    if (templateFieldCount.data && templateFieldCount.data > 0) {
      setCountCheck(true);
    } else {
      setCountCheck(false);
    }
  }, [templateFieldCount.data]);

  const [currentStep, setCurrentStep] = useState(0);

  const routes = [
    {
      path: '/reports/qc',
      breadcrumbName: 'QC Reports',
    },
    {
      path: `/reports/qc/${values.report}`,
      breadcrumbName: qcReportsId?.data?.record.bolAwb
        ? `${qcReportsId?.data?.record.type.toUpperCase()} ${qcReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: 'Add New QC Report Template',
    },
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const SelectToDelete = () => {
    setIsDisabled(false);
    setSelectToDelete(true);
  };

  const CancelDelete = () => {
    setIsDisabled(true);
    setSelectToDelete(false);
  };

  const DeleteSelectedImage = () => {
    setSelectToDelete(false);
    if (selectedImages.length > 0) {
      const newFileList = images.filter((file) => !selectedImages.includes(file));

      setImages(newFileList);
      setSelectedImages([]);
      const newGalleryImages = values.galleryImages.filter(({ id }) => !selectedImages.some((s) => s.id === id));

      setValue({
        galleryImages: newGalleryImages,
        images: newFileList,
      });
    }
  };

  const steps: Step[] = [
    {
      title: 'Template',
      content: (
        <TableTemplates
          params={{ status: 'true' }}
          createButton={false}
          selectedRows={values.template ? [values.template] : undefined}
          onRowSelection={(templates) => setValue({ template: templates[0] || 0 })}
        />
      ),
      isValid: !!values.template,
    },
    {
      title: 'Photos',
      content: (
        <Card
          title="Add Photos"
          extra={(
            <div>
              {selectToDelete === false && (
                <Button type="primary" onClick={SelectToDelete}>
                  Select to Delete
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={CancelDelete}>
                  Cancel
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={DeleteSelectedImage} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              )}
              <Button type="primary" onClick={handleVisibility} style={{ marginLeft: '10px' }}>
                Upload from Gallery
              </Button>
            </div>
          )}
        >
          <UploadReportPictures
            galleryImages={values.galleryImages}
            images={images}
            onUploadFilesChange={onUploadFilesChange}
            selectedImage={selectedImages}
            setSelectedImages={setSelectedImages}
            selectToDelete={selectToDelete}
          />
        </Card>
      ),
      isValid: !!values.images.length || !!values.galleryImages.length,
    },
    {
      title: 'Form',
      content: (
        <TemplateForm
          id={values.template}
          onSave={onSave}
          setIsDisabled={(disabled) => setIsDisabled(disabled)}
          setTemplateFields={(reportTemplateFields: TemplateFieldValue[]) => setValue({ reportTemplateFields })}
          templateFieldsValues={values.reportTemplateFields}
        />
      ),
      isValid: !!values.reportTemplateFields,
    },
  ];

  if (countCheck === false) {
    steps.pop();
  }

  return (
    <>
      <PageHeader
        title="Add New QC Report Template"
        extra={(
          <>
            {currentStep > 0 && (
              <Button type="default" onClick={prev}>
                Back
              </Button>
            )}
            {currentStep >= 0 && currentStep !== steps.length - 1 && (
            <Button type="primary" onClick={next} disabled={!steps[currentStep].isValid}>
              Next Step
            </Button>
            )}
            {currentStep === steps.length - 1 && (
              setIsDisabled(disable),
                <Button type="primary" disabled={isDisabled} onClick={onSave} loading={reportTemplateCreate?.loading}>
                  Save
                </Button>
            )}
          </>
        )}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        footer={(
          <Steps current={currentStep} style={{ maxWidth: '880px' }}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        )}
      />
      <Content>
        <GalleryModal
          visible={modalVisibility}
          title="QC gallery"
          handleVisibility={handleVisibility}
          setValue={setValue}
          checkedImages={values.galleryImages}
        />
        {steps[currentStep].content}
      </Content>
    </>
  );
};

export default CreateTemplate;
