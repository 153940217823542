import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/LpReports/Regenerate';
import { displayName } from '../../../config';
import LpReportsGenerateProvider from '../../../context/lpReportGenerate';

const LpReportRegenerate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Report Regenerate`;

  return (
    <Layout>
      <LpReportsGenerateProvider regenerate>
        <Content />
      </LpReportsGenerateProvider>
    </Layout>
  );
};

export default LpReportRegenerate;
