import React from 'react';
import { NavLink } from 'react-router-dom';
import { Logo as LogoCommon } from '../../../../Common/Icon';
import { useUnsavedRegenerate } from '../../../../../context/unsaveRegenerate';

import styles from './index.module.less';

const Logo: React.FC = () => {
  const { condition, regenerateUnsavedModal } = useUnsavedRegenerate();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!condition) {
      e.preventDefault();

      regenerateUnsavedModal('/records');
    }
  };

  return (
    <NavLink to="/records" onClick={(e) => handleClick(e)}>
      <div className={styles.logo}>
        <LogoCommon width="152" height="32" />
      </div>
    </NavLink>
  );
};

export default Logo;
