import React from 'react';
import { Layout } from 'antd';

import { displayName } from '../../../config';
import Content from '../../../components/Pages/QcReports/Regenerate';
import QcReportsGenerateProvider from '../../../context/qcReportGenerate';

const QcReportRegenerate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: QC Report Regenerate`;

  return (
    <Layout>
      <QcReportsGenerateProvider regenerate>
        <Content />
      </QcReportsGenerateProvider>
    </Layout>
  );
};

export default QcReportRegenerate;
