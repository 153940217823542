import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Card, message, PageHeader } from 'antd';

import { useQcReportsId } from '../../../../hooks/qcReports';
import QualityReportPreview from './QualityReportPreview';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateGetId } from '../../../../hooks/reportTemplate';

import styles from '../Generate/QcReportGenerateStep/index.module.less';

const QcProfileTemplate: React.FC = () => {
  const navigate = useNavigate();
  const { id: reportId = '', templateId = '' } = useParams<{ id: string; templateId: string; }>();

  const qcReportsId = useQcReportsId();
  const reportTemplateGetId = useReportTemplateGetId();

  useEffect(() => {
    if (reportId) {
      qcReportsId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (qcReportsId.error) {
      message.error(getMessageInError(qcReportsId.error));
      qcReportsId.clearError();
    }
  }, [qcReportsId.error]);

  useEffect(() => {
    if (templateId) {
      reportTemplateGetId.fetch(undefined, templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (reportTemplateGetId.error) {
      message.error(getMessageInError(reportTemplateGetId.error));
      reportTemplateGetId.clearError();
    }
  }, [reportTemplateGetId.error]);

  const routes = [
    {
      path: '/reports/qc/',
      breadcrumbName: 'QC Reports',
    },
    {
      path: `/reports/qc/${reportId}`,
      breadcrumbName: qcReportsId?.data?.record.bolAwb
        ? `${qcReportsId?.data?.record.type.toUpperCase()} ${qcReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: reportTemplateGetId?.data?.template.name || 'Template details',
    },
  ];

  return (
    <>
      <PageHeader
        title={reportTemplateGetId?.data?.template.name || 'Template details'}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <Card
          loading={reportTemplateGetId?.loading}
          title="Media"
          style={{ marginBottom: 24 }}
        >
          <div className={styles.wrapper}>
            {reportTemplateGetId.data?.prevImages?.map((id) => (
              <div key={id} className={styles.imageWrapper}>
                {reportTemplateGetId?.data?.images
                  ?.filter((image) => image.id === id)?.length ? (
                    <div
                      className={styles.date}
                      style={{ display: qcReportsId.data?.addDataStump ? 'block' : 'none' }}
                    >
                      {reportTemplateGetId.data?.images
                        ?.filter((image) => image.id === id)[0].timeStampString}
                    </div>
                  ) : null}
                <div
                  style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}/images/${id})` }}
                  className={styles.img}
                />
              </div>
            ))}
          </div>
        </Card>
        {reportTemplateGetId.data ? <QualityReportPreview template={reportTemplateGetId.data} /> : <Card loading />}
      </Content>
    </>
  );
};

export default QcProfileTemplate;
