import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, PageHeader } from 'antd';

import confirm from '../../../Common/ModalConfirm';
import LpReportGenerateStep from './LpReportGenerateStep';
import { QcReportTemplate } from '../../../../hooks/qcReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateUpdate } from '../../../../hooks/reportTemplate';
import { useContextLpReportGenerate } from '../../../../context/lpReportGenerate';

import styles from './index.module.less';

const LpReportGenerate: React.FC = () => {
  const navigate = useNavigate();
  const reportTemplateUpdate = useReportTemplateUpdate();
  const {
    isDisabled,
    onSave,
    values,
    setValue,
    lpReportsId,
    reportGenerate,
    timestamp,
    handleTimestamp,
  } = useContextLpReportGenerate();

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (reportTemplateUpdate.data && !reportTemplateUpdate.error) {
      setCurrentStep(currentStep + 1);
    }
  }, [reportTemplateUpdate.data]);

  useEffect(() => {
    if (reportTemplateUpdate.error) {
      getMessageInError(reportTemplateUpdate.error);
      reportTemplateUpdate.clearError();
    }
  }, [reportTemplateUpdate.error]);

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const routes = [
    {
      path: '/reports/lp',
      breadcrumbName: 'LP Reports',
    },
    {
      path: `/reports/lp/${lpReportsId?.data?.id}`,
      breadcrumbName: lpReportsId?.data?.record.bolAwb ? `AWB ${lpReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: 'Generate LP Report',
    },
  ];

  return (
    <>
      <PageHeader
        title={(
          <div className={styles.wrapBackText}>
            <span>Generate</span>
            <span className={styles.smallText}>{lpReportsId?.data?.type?.name}</span>
          </div>
        )}
        extra={(
          <>
            {currentStep > 0 && (
              <Button type="default" onClick={prev}>
                Back
              </Button>
            )}
            {currentStep < values.length - 1 && (
              <Button
                type="primary"
                onClick={next}
                disabled={!(values[currentStep].images.length && values[currentStep].reportTemplateFields
                  .filter((field) => field.templateField.required)
                  .every((field) => !!field.value))}
              >
                Next Step
                {' '}
                {`${currentStep + 1}/${values.length}`}
              </Button>
            )}
            {currentStep === values.length - 1 && (
              <Button
                type="primary"
                onClick={() => confirm({
                  onOk: async () => {
                    setIsLoading(true);
                    await onSave();
                    setIsLoading(false);
                  },
                  title: lpReportsId?.data?.record.uid ? 'Send to A-works?' : 'Generate PDF?',
                  okText: lpReportsId?.data?.record.uid ? 'Send to A-works' : 'Generate PDF',
                  content: 'Please make sure the report is completed correctly before generating.',
                })}
                loading={isLoading || reportGenerate?.loading}
                disabled={isDisabled}
              >
                {lpReportsId?.data?.record?.uid ? 'Send to A-works' : 'Generate PDF'}
              </Button>
            )}
          </>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        {values.length ? (
          <LpReportGenerateStep
            key={values[currentStep].id}
            timestamp={timestamp}
            handleTimestamp={handleTimestamp}
            values={values.find(({ id }) => id === values[currentStep].id) as QcReportTemplate}
            template={lpReportsId?.data?.reportTemplates.find((reportTemplate) => (
              reportTemplate.id === values[currentStep].id
            )) as QcReportTemplate}
            setValues={(value) => setValue(values.map((templateValues) => {
              if (templateValues.id === values[currentStep].id) {
                return value;
              }

              return templateValues;
            }))}
          />
        ) : <Card loading />}
      </Content>
    </>
  );
};

export default LpReportGenerate;
