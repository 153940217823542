import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Card, message, PageHeader } from 'antd';

import { useLpReportsId } from '../../../../hooks/lpReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateGetId } from '../../../../hooks/reportTemplate';

import styles from '../../QcReports/Generate/QcReportGenerateStep/index.module.less';

const LpProfileTemplate: React.FC = () => {
  const navigate = useNavigate();
  const { id: reportId = '', templateId = '' } = useParams<{ id: string; templateId: string; }>();

  const lpReportsId = useLpReportsId();
  const reportTemplateGetId = useReportTemplateGetId();

  useEffect(() => {
    if (reportId) {
      lpReportsId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (lpReportsId.error) {
      message.error(getMessageInError(lpReportsId.error));
      lpReportsId.clearError();
    }
  }, [lpReportsId.error]);

  useEffect(() => {
    if (templateId) {
      reportTemplateGetId.fetch(undefined, templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (reportTemplateGetId.error) {
      message.error(getMessageInError(reportTemplateGetId.error));
      reportTemplateGetId.clearError();
    }
  }, [reportTemplateGetId.error]);

  const routes = [
    {
      path: '/reports/lp/',
      breadcrumbName: 'LP Reports',
    },
    {
      path: `/reports/lp/${reportId}`,
      breadcrumbName: lpReportsId?.data?.record.bolAwb ? `AWB ${lpReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: lpReportsId.data?.type?.name || 'Template details', // reportTemplateGetId?.data?.template.name
    },
  ];

  return (
    <>
      <PageHeader
        title={lpReportsId.data?.type?.name || 'Template details'}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <Card
          title="Media"
          style={{ marginBottom: 24 }}
          loading={reportTemplateGetId?.loading}
        >
          <div className={styles.wrapper}>
            {reportTemplateGetId.data?.prevImages?.map((id) => (
              <div key={id} className={styles.imageWrapper}>
                {reportTemplateGetId?.data?.images
                  ?.filter((image) => image.id === id)?.length ? (
                    <div
                      className={styles.date}
                      style={{ display: lpReportsId.data?.addDataStump ? 'block' : 'none' }}
                    >
                      {reportTemplateGetId.data?.images
                        ?.filter((image) => image.id === id)[0].timeStampString}
                    </div>
                  ) : null}
                <div
                  style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}/images/${id})` }}
                  className={styles.img}
                />
              </div>
            ))}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default LpProfileTemplate;
