import React from 'react';

import { QcReportTemplate } from '../../../../../hooks/qcReports';

import RegenerateReportMedia from '../../../../Common/RegenerateReportMedia';

interface LpReportGenerateStep {
  values: QcReportTemplate;
  template: QcReportTemplate;
  setValues: (value: QcReportTemplate) => void;
  timestamp: boolean;
  handleTimestamp: (checked: boolean) => void;
  currentStep: number;
}

const LpReportRegenerateStep: React.FC<LpReportGenerateStep> = ({
  values,
  template,
  setValues,
  currentStep,
}) => (
  <RegenerateReportMedia
    values={values}
    setValues={setValues}
    template={template}
    isQcReport={false}
    currentStep={currentStep}
  />
);

export default LpReportRegenerateStep;
