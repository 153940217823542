import React from 'react';
import { Content } from 'antd/es/layout/layout';

import TableCompletedReports from './Table';

const CompletedReports: React.FC = (): JSX.Element => (
  <Content>
    <TableCompletedReports />
  </Content>
);

export default CompletedReports;
