import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, PageHeader } from 'antd';

import { useAuth } from '../../../../context/auth';
import TableLoggers from '../../../Common/TableLoggers';
import QualityReportFields from '../QualityReportFields';
import { useContextGenericTemplate } from '../../../../context/genericTemplate';

const routes = [
  {
    path: '/templates',
    breadcrumbName: 'Templates',
  },
  {
    breadcrumbName: 'Generic Template',
  },
];

const GenericTemplate: React.FC = () => {
  const { isRoleEnough } = useAuth();
  const navigate = useNavigate();
  const { onSave, values, setValue, isDisabled, templateGeneric, templatesUpdate } = useContextGenericTemplate();

  return (
    <>
      <PageHeader
        title="Generic Template"
        extra={(
          <>
            {isRoleEnough('admin') && (
              <Button type="primary" disabled={isDisabled} onClick={onSave} loading={templatesUpdate?.loading}>
                Save changes
              </Button>
            )}
          </>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <QualityReportFields
          onSave={onSave}
          loading={templateGeneric?.loading}
          templateFields={values.templateFields}
          setTemplateFields={(templateFields) => setValue({ templateFields })}
        />
        <div style={{ marginTop: '24px' }}>
          <TableLoggers entity="templates" />
        </div>
      </Content>
    </>
  );
};

export default GenericTemplate;
