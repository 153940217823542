import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/QcReports/CreateTemplate';
import { displayName } from '../../../config';
import QcReportCreateTemplateProvider from '../../../context/qcReportCreateTemplate';

const CreateTemplate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: QC Report Create Template`;

  return (
    <Layout>
      <QcReportCreateTemplateProvider>
        <Content />
      </QcReportCreateTemplateProvider>
    </Layout>
  );
};

export default CreateTemplate;
